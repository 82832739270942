.headerContainer {
  padding: 0px;
  margin: 0px;
  height: 100vh !important;
  overflow: hidden;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.mainPanelContainer {
  display: flex;
  height: 100vh;
  overflow-y: hidden;
}

.panelTable {
  width: 100%;
  background-color: #f2f4ff;
  /* background-color: #eeeff2; */
  padding: 20px;
  min-height: 95vh;
}

.panelHeadingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px;
  flex-wrap: wrap;
  position: sticky;
  top: 0px;
  padding-top: 10px;
  z-index: 9999;
  width: 76vw;
  background-color: white;
}
table {
  position: relative;
}
thead {
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 99999;
  background-color: white;
}
.tablescroll{
  position: relative;
  overflow: auto;
  height: calc(100vh - 25rem);
}
.dashboardtablescroll{
  position: relative;
  overflow: auto;
  height: calc(100vh - 20rem);
}
.dashboarddetailstablescroll{
  position: relative;
  overflow: auto;
  height: calc(100vh - 17rem);
}
.investortablescroll{
  position: relative;
  overflow: auto;
  height: calc(100vh - 14rem);
}
.supporttablescroll{
  position: relative;
  overflow: auto;
  height: calc(100vh - 16rem);
}
.dropdownInput {
  margin: 10px 5px;
  border: 2px solid lightgray;
  border-radius: 5px;
  color: grey;
  font-weight: bold;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5em 2em 0.5em 0.5em;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%);
  background-position: right 15px top 1em, right 10px top 1em;
  background-color: #fff;
  background-size: 5px 5px, 5px 5px;
  text-align: center;
  cursor: pointer;
}

.searchInputContainer {
  border-radius: 5px;
  border: 2px solid lightgray;
  padding: 7px;
  outline: none;
  display: flex;
  align-items: center;
  margin: 10px 5px;
}

.searchLeadUser {
  border-radius: 5px;
  border: 2px solid lightgray;
  padding: 7px;
  outline: none;
  display: flex;
  align-items: center;
  width: 20%;
}

.searchInput {
  outline: none;
  color: #091b41;
  width: 100%;
}

.dropDownSection {
  background-color: white;
  padding: 15px;
  overflow-y: auto;
  height: calc(100vh - 14rem);
}
.dropDownSection-big {
  background-color: white;
  padding: 15px;
  overflow-y: auto;
  height: calc(100vh - 5rem);
  position: relative;
}

/* pagination */

.paginationBttns {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: end;
  margin-top: 20px;
  color: #091b41;
  align-items: center;
  padding: 4px;
}

.paginationBttns a {
  padding: 10px;
  border: 1px solid #091b41 !important;
  cursor: pointer;
}

.paginationActive a {
  color: white !important;
  background-color: #091b41;
}

.paginationDisabled a {
  color: lightgray;
  border: 1px solid lightgray !important;
}

@media only screen and (max-width: 650px) {
  .panelHeadingContainer {
    justify-content: space-evenly;
  }
}
