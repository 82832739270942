table {
  font-family: '';
  border-collapse: collapse;
  width: 100%;
  text-align: right;
}

table td {
  padding: 10px;
  color: black;
  height: 60px;
  width: 100px;
  position: relative;
  font-family: 'Nunito', sans-serif;

}

table tr {
  background-color: white;
  padding: 10px;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  
}

tfoot th {
  text-align: center;
  height: 80px;
  font-weight: bold;
  font-size: 16px;
  color: grey !important;
  padding: 10px;
}

table th {
  text-align: center;
  height: 80px;
  font-weight: bold;
  font-size: 15px;
  color: grey !important;
}
