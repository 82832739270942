@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;1,800&display=swap');

body {
  font-family: 'Nunito', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .inputStyle {
    @apply items-center text-darkblue outline-none p-1 border-b-2 border-lightGrey ;
  }
  .loadingBtn {
    @apply bg-darkblue hover:bg-lightButton w-32 active:bg-lightButton disabled:opacity-80 disabled:cursor-not-allowed  focus:outline-none focus:ring focus:ring-lightButton px-5 py-2  text-sm font-extrabold  text-white rounded-xl flex justify-center items-center 
  }
  .modalContainer {
    @apply inline-block w-full max-w-lg shadow-xl text-left mt-32 items-center  transition-all transform bg-white  border border-lightGrey rounded-lg overflow-auto
  }
  .alignment {
    @apply flex justify-center items-center 
  }
  .alignmentBetween {
    @apply flex items-center  justify-between
  }
  .alignmentStart {
    @apply flex items-center justify-start
  }
  .alignmentStartPreview {
    @apply flex items-center justify-start h-[140px] overflow-hidden
  }
  .alignmentAround {
    @apply flex items-center justify-around
  }
}

/* Disable the default eye icon in Edge */
input[type='password']::-ms-reveal {
  display: none;
}