.headerContainer {
  padding: 0px;
  margin: 0px;
  height: 100vh !important;
}
.mainContainer {
  display: flex;
  background-color: #eeeff2;
  min-height: 100vh !important;
}

.heading {
  color: #091b41;
  font-weight: bolder;
}
.hrLine {
  color: #aeacb6 !important;
}
.documentImageBox {
  height: 200px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 20px;
  width: 400px;
  margin-bottom: 10px;
}
.imageContainer {
  height: 200px;
  object-fit: contain;
  width: 400px;
}
.imageContainerNew {
  height: 110px;
  object-fit: contain;
  width: 100px;
}
@media only screen and (max-width: 1000px) {
  .documentImageBox {
    width: 320px;
  }
}
@media only screen and (max-width: 1200px) {
  .addressSection {
    width: 100%;
  }
  .managementContainer {
    flex-direction: column;
  }
}
