.headingText {
  color: #939199 !important;

  font-size: 14px;
  font-weight: 400;
}

.supportRequest {
  color: #091B41 !important;

  font-size: 18px;
  font-weight: bolder !important;
}

.headingTitle {
  color: #051D56 !important;
  font-size: 16px;
  max-width: 100%;
  margin-top: 10px;
  font-weight: bolder;
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.detailsInput {
  width: 270px;
  margin: 10px 20px;
}

.tooltipSection {
  background-color: #051D56 !important;
  width: 260px;
}

@media only screen and (max-width: 1600px) {
  .detailsInput {
    width: 260px;
  }
}

@media only screen and (max-width: 1500px) {
  .detailsInput {
    width: 240px;
  }
}

@media only screen and (max-width: 1380px) {
  .detailsInput {
    width: 220px;
  }
}

@media only screen and (max-width: 980px) {
  .detailsInput {
    width: 200px;
  }
}

@media only screen and (max-width: 680px) {
  .detailsInput {
    width: 100%;
  }
}