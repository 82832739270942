.editableField {
  width: 270px;
  margin: 10px 0px;
  
}
.editableFieldSelect {
  width: 270px;
  margin: 10px 0px;

}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 1600px) {
  .editableField {
    width: 260px;
  }
  .editableFieldSelect {
    width: 260px;
  }
}
@media only screen and (max-width: 1500px) {
  .editableField {
    width: 240px;
  }
  .editableFieldSelect {
    width: 240px;
  }
}
@media only screen and (max-width: 1380px) {
  .editableField {
    width: 180px;
  }
  .editableFieldSelect {
    width: 180px;
  }
}
@media only screen and (max-width: 980px) {
  .editableField {
    width: 140px;
  }
  .editableFieldSelect {
    width: 140px;
  }
}

@media only screen and (max-width: 750px) {
  .editableField {
    width: 100%;
  }.editableFieldSelect{
    width: 300px;
  }
}
