.panelSidebar {
  background-color: #091b41;
  width: 300px;
  color: #4e73df;
  font-weight: bold;
  min-height: 100vh;
}

.panelTableContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #f2f4ff;
}

.logo {
  padding: 20px;
}

.categoriesContainer {
  margin-top: 30px;
}

.isActive {
  color: #091b41 !important;
  background-color: #eeeff2 !important;
}

.not-active {
  color: #eeeff2 !important;
  background-color: #091b41 !important;
}

.ulContainer {
  font-size: medium;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 10px 0px 0px 10px;
  margin: 4px;
  padding: 10px 10px 10px 45px;
}

.ulContainerActive {
  padding: 10px;
  margin: 5px 0px 5px 15px;
  border-radius: 10px 0px 0px 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: start;
}

@media only screen and (max-width: 1300px) {
  .panelSidebar {
    width: 300px;
  }
}

@media only screen and (max-width: 750px) {
  .panelSidebar {
    width: 300px;
  }

  .ulContainerActive {
    flex-direction: column;
  }

  .ulContainer {
    flex-direction: column;
    margin: 5px 0px 5px 5px;
  }

  .sidebarHeading {
    font-size: 12px;
  }
}

@media only screen and (max-width: 650px) {
  .panelSidebar {
    display: none;
  }
}