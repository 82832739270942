.cardsHeader {
  background-color: white;
}
.topCardsContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 20px;
  background-color: white;
  margin: 5px;
  border-radius: 10px;
  width: 280px;
}
.iconContainer {
  background-color: #eeeff2;
  padding: 10px;
  border-radius: 50px;
}
.cardsTitle {
  color: grey;
  font-size: 14px;
  width: 100%;
  font-weight: bolder;
}
@media only screen and (max-width: 1700px) {
  .topCardsContainer {
    width: 240px !important;
  }
}
@media only screen and (max-width: 1480px) {
  .topCardsContainer {
    width: 200px !important;
  }
  .cardsTitle {
    font-size: 11px;
  }
}
